.appsIcons{
    width: 24px;
    margin: 0 -10px 0 10px !important;
    position: static;
}
.ant-layout-sider-collapsed .appsIcons{
    margin:0 !important;
}
.no-stroke{
    stroke: none !important;
}
.ant-layout-sider-collapsed .inboxIcon img {
    width: 19px !important;
}
.ant-layout-sider-collapsed .contactsIcon img {
    width: 20px !important;
}
li.ant-menu-item.templatesIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 0 0 0 10px !important;
    height: 47px !important;
    width: 52px;
}
li.ant-menu-item.templatesIcon svg {
    margin: 0 !important;
}
/*.ant-layout-sider .ant-menu-item img {*/
/*    width: 21px !important;*/
/*}*/
/*.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-item img {*/
/*    width: inherit !important;*/
/*}*/