
// common style
// for dropdown menu || select option // 
.ant-dropdown-menu {
    border: 1px solid #D9D9D9;
    text-align: center;
    box-shadow: 0 0 10px -6px #999;
    border-radius: 4px;
    min-width: 160px;
    max-width: 100%;
    li {
      border-top: 1px solid #D9D9D9;
      font-weight: 500;
      padding: 11px 5px;
      span,a,div{
        font-size: 14px;
        color: #8e8e8e;
        line-height: 20px;
      }
      
      &:first-child{
        border-top:none;
      }
      &:last-child{
        border-bottom:none;
      }
    }
  }
  .ant-select-dropdown {
    width: 176px !important;
    .ant-select-item {
      font-size: 15px;
      color: #8e8e8e;
      padding: 11px 5px;
      line-height: 26px;
      text-align: center;
    }
  }